@charset 'utf-8';

@import "settings";
@import "global/visibility-mixins";
@import "modules/footer";

// CTA block almost always sits in footer
@import "blocks/block-cta";

// Components usually required below the fold
@import "components/forms";
// @import "components/label";
@import "components/list";
@import "components/mon-modal";
@import "components/pagination";
@import "components/search-snippet";
@import "components/table";
@import "components/widget";

// Mobile menus can be loaded below the fold as only required when hamburger clicked
// @import "header/mobile-accordion-menu";
@import "header/mobile-drilldown-menu";

// Mega menu styles can be loaded below the fold rather than immediatly on page load
@import "header/desktop-mega-menu-styles";

// Templates we can load below the fold
@import "templates/single-event";
@import "templates/single-team";
@import "templates/search";
