$mega-menu-width: $global-width; // Change to 100% to make full width.

.desktop-menu {
  &.mega-menu {
    position: static;

    &.mon-is-active ul.desktop-menu__dropdown.dropdown-active {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
      padding: $global-padding * 3 $global-padding;

      @include breakpoint(xlarge) {
        padding-left: calc((100% - #{$global-width}) / 2);
        padding-right: calc((100% - #{$global-width}) / 2);
      }
    }

    // &:first-child {
    //   > ul.desktop-menu__dropdown {
    //     opacity: 1;
    //     display: grid;
    //     grid-template-columns: repeat(3, 1fr);
    //     width: 100%;
    //     padding: $global-padding * 3 $global-padding;

    //     @include breakpoint(xlarge) {
    //       padding-left: calc((100% - #{$global-width}) / 2);
    //       padding-right: calc((100% - #{$global-width}) / 2);
    //     }
    //   }
    // }

    .desktop-menu__dropdown {
      a {
        padding: $global-padding $global-padding 0;
      }
      .menu-item__title {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }

    .menu-item {
      flex-direction: column;
    }

    .menu-icon-wrap {
      margin-right: $global-margin;
      width: 54px;
      height: 54px;
    }

    .menu-icon {
      width: 100%;
      height: 100%;
      border-radius: 54px;
      border: 3px solid $white;
    }

    // Sub level dropdowns
    .desktop-menu__dropdown--level-0 {
      align-items: flex-start;

      .menu-item-has-children {
        > a {
          color: $body-font-color;
          cursor: initial;
        }
      }

      .menu-item__nav-link {
        &::after {
          display: none;
        }
      }
    }
    .desktop-menu__dropdown--level-1 {
      opacity: 1;
      display: flex;
      left: auto;
      position: relative;
      // top: auto;
      box-shadow: none;
      margin-bottom: auto;
      bottom: auto;
    }
  }
}
