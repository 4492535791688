$footer-text-color: $secondary-color;
$footer-font-size: 18px;
$footer-font-size-small: $footer-font-size;

.footer {
  border-top: 3px solid $secondary-color;
  color: $footer-text-color;
  position: relative;
  text-align: left;


  &::before {
    background-image: url('../images/footer-logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: 50px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -29px;
    width: 160px;
    z-index: 1;

    @include breakpoint( medium ) {
      height: 75px;
      top: -42px;
      width: 205px;
    }
  }

  p:not(.lead),
  li,
  a:not(.button) {
    font-size: $footer-font-size;
  }

  &__row {
    @include breakpoint( large ) {
      margin: 0 auto;
    }
  }

  // ---------------------------
  // TOP SECTION
  // ---------------------------
  &__top {
    background-color: $white;
    padding: $global-padding * 3 0;

    @include breakpoint( large ) {
      padding-top: $global-padding * 4.5;
    }
  }

  // ---------------------------
  // BOTTOM SECTION
  // ---------------------------
  &__bottom {
    background-color: $white;
    font-size: $footer-font-size-small;
    padding: 0 0 $global-padding * 2;

    @include breakpoint(medium) {
      display: flex;
      padding: $global-padding * 2 0;
    }

    p,
    li,
    a {
      font-size: $footer-font-size-small;
    }
  }

  &__top,
  &__bottom {
    // ---------------------------
    // LINKS
    // ---------------------------
    a:not(.button) {
      color: $footer-text-color;
    }
  }


  &__legal-text,
  &__credits {
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  // ---------------------------
  // WIDGETS
  // ---------------------------
  &__widgets {
    display: grid;
    grid-gap: $default-grid-gap * 1.5;

    @include breakpoint(medium) {
      grid-gap: $default-grid-gap;
      grid-template-columns: 2fr 1fr 1fr 2fr;
    }
  }

  // ---------------------------
  // BASIC UL STYLES
  // ---------------------------
  ul {
    list-style-type: none;
    margin: 0;

    li {
      margin-bottom: $global-margin / 2;

      &:last-child {
        margin-bottom: 0;
      }
    }


    &.footer-legal-menu {

      @include breakpoint(medium) {
        display: flex;
        justify-content: flex-start;
      }

      li {
        @include breakpoint(medium) {
          margin: 0 $global-margin 0 0;
        }
      }
    }
  }

} //footer

.footer__social {
  .social-link {
    border-radius: 50%;
    background-color: $brand-yellow;
    border: 4px solid $primary-color;
    padding-top: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .svg-icon--social {
    fill: $primary-color;
    height: 20px;
    width: 20px;
  }
}
