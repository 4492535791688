$pagination-link-dimension: 40px;

.pagination,
.page-numbers {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;

  li {
    align-items: center;
    display: flex;
    margin: 0 $global-margin / 4;

    @include breakpoint( medium ) {
      margin: 0 $global-margin / 2;
    }
  }

  a,
  span,
  .prev,
  .next {
    align-items: center;
    border: 1px solid transparent;
    color: $secondary-color;
    cursor: pointer;
    display: flex;
    font-family: $header-font-family;
    font-size: 28px;
    height: $pagination-link-dimension;
    justify-content: center;
    line-height: $pagination-link-dimension;
    transition: color .4s ease;
    width: $pagination-link-dimension;

    &.current,
    &:hover {
      position: relative;

      &::after {
        content: url('../images/underline.svg');
        position: absolute;
        bottom: -$global-margin;
        left: 0;
        right: 0;
      }

    }

  }

  .prev,
  .next {
    background-color: transparent;

    // hack to hide the woo arrows
    font-size: 0px;

    height: 35px;
    position: relative;
    transition: all .3s ease;
    width: 50px;

    &::before {
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &:hover {
      &::after {
        content: none;
      }
    }
  }

  .prev {
    &::before {
      content: '';
      background-image: url('../images/icons/arrow-long-left.svg');
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
      width: 100%;
    }
  }

  .next {
    &::before {
      content: '';
      background-image: url('../images/icons/arrow-long.svg');
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
      width: 100%;
    }
  }
}

.woocommerce-pagination {
  @include breakpoint( large ) {
    padding-bottom: $global-margin * 3;
  }
}
