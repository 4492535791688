// Mobile menu (Drilldown).

// <ul>
.drilldown {
  background-color: $white;
  list-style: none;
  height: 100%;
  margin: 0;
  padding: $global-padding 0 $global-padding * 3;
  position: relative;
  background-size: contain;
  background-image: url('../images/off-canvas-bg.svg');
  background-position: center bottom;
  background-repeat: no-repeat;

  li {
    list-style: none;

    // Anchor styles.
    a {
      align-items: center;
      background-color: $white;
      color: $secondary-color;
      display: flex;
      flex-direction: row-reverse;
      font-family: $header-font-family;
      font-size: rem-calc(16);
      justify-content: flex-end;
      padding: 14px ($global-padding * 2);
      position: relative;
      width: 100%;

      &::after {
        background-image: url('../images/icons/drilldown-border-bottom.svg');
        background-repeat: repeat-x;
        bottom: 0;
        content: '';
        display: inline-block;
        height: 3px;
        left: 23px;
        position: absolute;
        right: 0;
        width: calc(100% - 56px);
      }

      .menu-item__description {
        display: block;
        font-weight: $global-weight-normal;
      }

      .menu-icon-wrap {
        margin-right: $global-margin;
        width: 42px;
        height: 42px;
      }

    }

    &:last-child {
      a {
        &::after {
          display: none;
        }
      }
    }

    &.link-normal {
      &:first-child {
        margin-top: $global-margin;
      }

      a {
        font-family: $body-font-family;
        padding-bottom: 0;
        &::after {
          content: none;
        }
      }
    }

    // Back <button>.
    .drilldown__back {
      align-items: center;
      color: $secondary-color;
      display: flex;
      font-size: rem-calc(16);
      padding-left: $global-padding * 2;
      margin-bottom: $global-margin;

      &::before {
        content: url('../images/icons/red-chevron-left.svg');
        margin-right: $global-padding;
      }
    }

    // <li> Parent
    &.menu-item-has-children {
      align-items: center;
      display: flex;

      // Chevrons for links with children sub menus.
      &::after {
        background-image: url('../images/icons/arrow-right.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: $white;
        content: '';
        height: 23px;
        width: 26px;
        position: absolute;
        right: $global-padding * 2;
      }

      // <ul> Children links with the Back button in them.
      .nested {
        background-color: $white;
        height: 100%;
        margin-left: 0;
        left: 0;
        padding: $global-padding * 2 0 $global-padding;
        position: absolute;
        top: 0;
        transform: translateX(100%);
        transition: all .2s ease-in-out;
        width: 100%;
        z-index: 1;

        // Visually hide the sub menus so they are not tab indexed.
        &[aria-hidden="true"] {
          visibility: hidden;
        }

        // Nested <li> with child sub menus.
        .menu-item-has-children {
          // Need to remove the padding or the chevrons go off screen.
          max-width: calc(100% - #{$global-padding * 2});
        }

        &.menu-level-0 {
          overflow-Y: scroll !important;
          overflow-X: hidden !important;
          height: 68vh;
        }

        // Apply the background pattern to smaller menu items. @todo janky but it works!
        //&#nested-01,
        //&#nested-12,
        //&#nested-13,
        //&#nested-14 {
        //  background-size: contain;
        //  background-image: url('../images/off-canvas-bg.svg');
        //  background-position: center bottom;
        //  background-repeat: no-repeat;
        //  height: 100%;
        //}
      }
    }
  }
}

.mobile-mini-nav {
  background-color: $white;
  list-style-type: none;
  margin: 0;
  padding: $global-padding / 2 $global-padding * 2 $global-padding * 2;

  li {
    a {
      color: $secondary-color;
      display: inline-block;
      padding: $global-padding / 2 0;
    }
  }
}
