.search-snippet {
  border-bottom: 1px solid $medium-gray;
  margin-bottom: $global-margin * 2;
  padding-bottom: $global-padding * 2;

  &:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &__label {
    font-size: 14px;
  }

  &__title {
    @extend .h3 !optional;

    margin: $global-margin 0;
  }

  &__title-link {
    color: $header-color;
    transition: .2s linear color;

    &:hover {
      color: $primary-color;
    }
  }
}
