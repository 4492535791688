@import '../settings';

.cta {

  &__grid {
    @include mon-cols(7);

    &--center {
      text-align: center;
    }
  }

  &__content {
    color: $secondary-color;
    margin-bottom: $global-margin * 2;
    position: relative;

    &::before {
      @include breakpoint( medium ) {
        content: '';
        background-image: url('../images/green-stars.svg');
        position: absolute;
        top: $global-margin * 3;
        left: 0;
        width: 120px;
        height: 110px;
        background-size: contain;
      }
    }

  }

  &__title {
    margin-top: $global-margin * 2;
  }

  &__buttons {
    // Remove the margin from the buttons.
    .button {
      margin-bottom: 0;

      & + .button {
        @include breakpoint(small only) {
          margin-top: $global-margin;
        }
      }

      &--cta-one {
        @include button(button--outline-primary);
      }

      &--cta-two {
        @include button(button--secondary);
      }
    }
  }

  &--global {
    background-color: $brand-yellow;
    color: $white;
    margin-top: $global-margin * 9;
    padding: $global-padding * 2 0 $global-padding * 6;

    &::before {
      -webkit-mask-image: none;
      background-color: $brand-yellow;
      background: inherit;
      content: '';
      height: 100%;
      mask-image: none;
      position: absolute;
      top: 0;
      transform-origin: left top;
      transform: skewY(-5deg);
      width: 100%;
      z-index: 1;
    }

    &::after {
      content: none;
    }

    .cta__grid {
      position: relative;
      z-index: 1;
    }

    .cta__content {
      overflow: visible;

      &::before {
        content: none;

        @include breakpoint( large ) {
          background-image: url('../images/newsletter-icon.svg');
          background-position: center bottom;
          background-repeat: no-repeat;
          bottom: - $global-padding * 4;
          content: '';
          height: 175px;
          left: - 260px;
          top: $global-padding * 4.5;
          width: 235px;
        }

      }
      &::after {
        content: none;

        @include breakpoint( large ) {
          background-image: url('../images/white-stars.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 155px;
          position: absolute;
          right: - 240px;
          top: - $global-padding * 8;
          width: 100px;
        }

      }
    }

    .cta__image {
      max-width: 355px;
      margin-right: 40px;
    }

    .cta__backtotop {
      display: inline-block;
      margin-top: $global-margin * 4;
    }


  }
}

// Social follow in footer
.social-follow {
  text-align: center;

  &__grid {
    &--instagram {
      margin-bottom: $global-margin * 3;
    }
  }

  p {
    color: $secondary-color;
    margin-bottom: $global-margin * 3;
  }

  &__icon {
    margin-right: $global-margin;

    &:last-child {
      margin-right: 0;
    }
  }
}
