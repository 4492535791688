// mon-modal, template-parts/mon-modal.php
.mon-modal {
  height: 0;
  left: 0;
  position: absolute;
  width: 0;

  // Default state. Changed via the js.
  .hidden {
    display: none;
  }

  // X icon at the top of the modal.
  &__close {
    position: absolute;
    right: $global-margin;
    top: $global-margin / 2;
    z-index: 3;

    svg path {
      transition: all .3s ease;
    }

    &:hover {
      cursor: pointer;

      svg path {
        stroke: $primary-color;
      }
    }
  }

  // Modal main container. The box that contains the modal content.
  [role="alertdialog"],
  [role="dialog"] {
    border-radius: 10px;
    position: absolute;
    width: calc(100% - #{$global-margin * 2});
    margin: 0 auto;
    top: 30vh;
    left: 0;
    right: 0;
    padding: $global-padding;

    @include breakpoint(medium) {

      left: 50vw;
      max-width: calc(768px - #{$global-margin * 2});
      min-height: auto;
      padding: $global-padding * 1.5;
      right: auto;
      top: 63vh;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
    }
  }

  // Modal <h2> titles.
  &__title {

  }

  // Modal buttons container. Cancel and the more advanced ones.
  &__buttons {

    // Remove any default bottom button margins.
    .button {
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }
  }

  .dialog_desc {
    padding: $global-padding;
  }

  //------------------------
  // Backdrop/background.
  //------------------------
  &__dialog-backdrop {
    bottom: 0;
    display: none;
    left: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;

    // Shows when the modal is opened.
    &.active {
      background: rgba($black, .3);
      display: block;
      z-index: 100;
    }
  }

}

// This is added to the body class when a dialog is opened. It stops the page from scrolling.
.has-dialog {
  overflow: hidden;
}

