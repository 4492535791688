.widget {
  margin-bottom: $global-margin * 2;
}

.widget-title {
  display: block;
  font-family: $header-font-family;
  margin-bottom: $global-margin / 2;

  @include breakpoint( medium ) {
    margin-bottom: $global-margin;
  }
}

.textwidget {
  & > *:last-child {
    margin-bottom: 0;
  }

  p .button {
    margin-bottom: 0;
  }
}
