$animation-duration: 0.2s;
$input-border-focus: 1px solid $primary-color;
$input-border: 1px solid $form-borders;
$input-height: rem-calc(50);
$input-padding: rem-calc(19);

@mixin label-active() {
	color: $secondary-color;
	font-size: 0.75rem;
	transform: translateY(-14px);
}


// ========================
// Input.
// ========================
// Only disable outline on mouse @todo im not even sure this is correct tbh?
[data-whatintent='mouse'] *:focus {
  outline: none;
}

input[type="email"],
input[type="number"],
input[type="password"],
input[type="text"],
input[type="tel"],
select {
  border-radius: 6px;
  color: $body-font-color;
  font-size: $global-margin;
  height: $input-height;
  padding: $input-padding;
}

input,
textarea {
  border: $input-border;
  box-shadow: none;
  color: $body-font-color;
  width: 100%;

  &::placeholder {
    color: $medium-gray;
  }

  &:active,
  &:focus {
    border: $input-border-focus;
    box-shadow: none;
  }
}

// ========================
// Select.
// ========================

select {
  appearance: none;
  background-color: transparent;
  background-image: url('../images/icons/chevron-down.svg');
  background-position: calc(100% - #{$global-margin}) center;
  background-repeat: no-repeat;
  border: $input-border;
  color: $body-font-color;
  font-size: 16px;
  display: block;
  height: $input-height;
  margin-bottom: 0;
  padding: 0 $input-padding;
  width: 100%;

  option {
    padding: 0;
  }

  @include breakpoint(medium) {
    margin-bottom: 0;
  }

  &:active,
  &:focus {
    border: $input-border-focus;
    box-shadow: none;
  }

  // Multi select. @todo this is a bit minging but its 100% better than what was there!
  &[multiple] {
    padding: $global-padding;

    option {
      margin-bottom: $global-margin;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

// Select fucking 2....

.select2-selection--single {
  text-align: left;
}

.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: $input-height;
  line-height: $input-height;
  padding: 0;
}

.select2-container .select2-selection--single {
  padding: 0 $global-padding;
}

.select2-container .select2-selection--single, .select2-container--default .select2-selection--single .select2-selection__rendered, .select2-container--default .select2-selection--single .select2-selection__arrow {
  right: $global-margin * 1.25;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  content: url('../images/icons/chevron-down.svg');
  border: none;
  height: 8px;
  width: 15px;
  top: calc(50% - 2px);
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: $body-font-color;
}

// ========================
// Textarea.
// ========================

textarea {
  border-radius: 6px;
  font-size: $global-margin;
  padding: $input-padding;
}

// ========================
// Label.
// ========================

label,
legend {
  color: $body-font-color;
  display: inline-block;
  font-family: $header-font-family;
  font-weight: $global-weight-medium;
  text-transform: lowercase;
  margin-bottom: $global-margin / 2;

  &.checkbox {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    input {
      margin-right: $global-margin / 2;
      width: auto;
    }

    span {
      font-family: $body-font-family;
    }
  }
}

// ========================
// Fieldset.
// ========================

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
