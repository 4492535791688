// Custom List options.
.list {
  list-style: none;
  margin-left: 0;

  @include breakpoint(medium) {
    break-inside: avoid;
    column-fill: balance;
    column-gap: $global-margin * 2;
    page-break-inside: avoid;
  }

  &--two {
    @include breakpoint(medium) {
      columns: 2;
    }
  }
}

.main-container, 
.entry-content {
  ul,
  ol {
    li {
      margin-bottom: $global-margin / 1.2;
    }
  }
}