$table-background: transparent;
$table-border: 1px solid $light-gray !default;
$table-foot-background: $light-gray;
$table-foot-font-color: $body-font-color;
$table-head-background: $light-gray;
$table-head-font-color: $body-font-color;
$table-padding: rem-calc(8 10 10) !default;

table {
  border-collapse: collapse;
  border-radius: 0;
  margin-bottom: 0;
  width: 100%;
}

thead,
tbody,
tfoot {
  border: $table-border;
  background-color: $table-background;
}

// Caption
caption {
  padding: $table-padding;
  font-family: 'GothamBold';
}

// Table head
thead {
  background: $table-background;
  color: $table-head-font-color;
}

// Table foot
tfoot {
  background: $table-background;
  color: $table-foot-font-color;
}

// Table head and foot
thead,
tfoot {
  // Rows within head and foot
  tr {
    background: transparent;
  }

  // Cells within head and foot
  th,
  td {
    padding: $table-padding;
    font-family: 'GothamBold';
    text-align: left;
  }
}

tr {
  border-bottom: 0;
  border-bottom: $table-border;
  background-color: $table-background;
}

tbody {
  th,
  td {
    padding: $table-padding;
  }
}
