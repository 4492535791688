.search-types {

  &__title {
    @extend .h3 !optional;
  }

  // <ul>
  &__list {

    list-style: none;
    margin: 0;

    @include breakpoint( medium ) {
      display: flex;
      gap: $global-margin / 2;
      flex-wrap: wrap;
    }

    .button {
      margin-bottom: 0;
      width: 100%;

      @include breakpoint( medium ) {
        width: auto;
      }

      @include breakpoint(large) {
        margin-bottom: 0;
      }

      &.is-active {
        @include button(button--primary);
      }
    }
  }
}
