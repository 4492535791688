.single-team {


  &__header {
    @extend .block !optional;

    margin-left: auto;
    margin-right: auto;
    max-width: $global-width;
    padding: 0 $global-padding;

    @supports( display: grid ) {
      display: grid;
      grid-row-gap: $global-margin;
      grid-template-columns: $global-padding repeat( 12, 1fr ) $global-padding;
      grid-template-rows: min-content auto 1fr;
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      padding: 0;

      @include breakpoint( xlarge ) {
        grid-template-columns: calc( ( 100vw - #{$global-width - $global-padding * 2} ) / 2 ) repeat( 12, 1fr ) calc( ( 100vw - #{$global-width - $global-padding * 2} ) / 2 );
      }
    }

    // A stacked header variant
    &.vertical {
      .single-team__title {
        background-color: $primary-color;
        grid-column: 1 / -1;
        padding: $global-padding * 2 0;
        text-align: center;
      }

      .single-team__image {
        grid-row: 2 / 3;

        @include breakpoint( medium ) {
          grid-row: 2 / -1;
        }
      }
    }

  }

  &__title {
    grid-column: 2 / span 6;
    grid-row: 1;
  }

  &__details {
    grid-column: 2 / span 6;
    grid-row: 2;
    padding-right: $global-padding;
  }

  &__image {
    grid-column: 8 / span 6;
    grid-row: 1 / 3;
    height: 100%;
    object-fit: cover;

    @include breakpoint( medium ) {
      grid-row: 1 / -1;
    }
  }

  &__excerpt {
    grid-column: 2 / span 12;
    grid-row: 3;
    padding-right: $global-padding;

    @include breakpoint( medium ) {
      grid-column: 2 / span 6;
    }
  }

}
