.event-header {
	@extend .block !optional;
}

.event-panel {
	@extend .block !optional;

	border: 1px solid $light-gray;
	padding: $global-padding * 2;
}

@include breakpoint(medium) {
	.event-panel {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 1rem;
	}
}

.event-panel__list {
	list-style: none;
	margin: 0 0 1rem;
}

.event-panel__list-item {
	margin-bottom: $global-margin / 2;
}

.event-panel__subheading {
	display: block;
	font-weight: bold;
}

.event-panel__details {
	grid-column: 1 / span 1;
}

.event-panel__venue {
	grid-column: 2 / span 1;
}

.event-panel__organiser {
	grid-column: 3 / span 1;
}

.event-panel__map {
	grid-row: 2;
	grid-column: 1 / -1;
}

.event-map-container {
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;
}

.event-map-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}
